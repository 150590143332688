import React from 'react';
import { VarietyPackIcon } from '../../modules/Icons';
import { QuestionIcon, EIcon } from '../../modules/Icons';
import { Tooltip } from 'react-tooltip';
import { TagContainerProps } from '../../types/components/shared/TagContainerTypes';
import { isLegacy } from '../../helpers/subscriptionHelpers';
import { connect } from 'react-redux';
import { ApplicationState } from '../../types/state/storeTypes';

export const avfxplusFlat =
  'uppercase w-[50px] h-[20px] font-bold text-white text-[12px] bg-[#4D5457] rounded-[3px] px-[8px] py-[3px]';

export const containerClassCheck = (props) => {
  return props.containerClasses?.includes('big');
};


export const RenderEssentialsMarker = ({ membershipTier, isCollectionPage }): React.ReactElement => {
  if (membershipTier === 'silver') {
    const size = isCollectionPage ? 'w-[40px] h-[40px] mr-4 stroke-none' : 'w-[20px] h-[20px] lg:w-[30px] lg:h-[30px] stroke-none';
    return <EIcon className={size} />;
  }
}

const TagContainer = (props: TagContainerProps) => {
  const {
    popular,
    newRelease,
    free,
    varietyPack,
    membershipTier,
    containerClasses,
    showTooltip,
    avfxplus,
    custom,
    className,
    text,
    subscription,
    isCollectionPage = false,
    forEssentials = false
  } = props;
  const isLegacyUser = isLegacy(subscription);
  const tagClasses = 'px-[6px] pb-[1px] pt-[1px] rounded-[3px] font-bold';

  const bigOtherTagClasses = props.containerClasses?.includes('big')
    ? 'h-[25px] px-[6px] !text-[14px] !leading-[24px] !font-bold !rounded-[2px] uppercase'
    : tagClasses;

  const bigBaseClass = 'h-[25px] !text-[14px] !leading-[24px] !font-[700]';
  let bigTagClasses = '';

  if (containerClassCheck(props)) {
    bigTagClasses += bigBaseClass + '';
  }

  if (membershipTier === 'silver' && containerClassCheck(props)) {
    bigTagClasses += ' w-[81px]';
  } else if (membershipTier === 'gold' && containerClassCheck(props)) {
    bigTagClasses += ' w-[73px]';
  } else if (membershipTier == 'elite' && containerClassCheck(props)) {
    bigTagClasses += ' w-[72px]';
  }

  bigTagClasses = bigTagClasses.trim();

  const renderLegacyMembershipTierMarker = (): React.ReactElement => {
    if (membershipTier !== 'silver' && membershipTier !== 'gold' && membershipTier !== 'elite')
      return;
    return (
      <span className={`${membershipTier}-tag avfx-tag ${bigTagClasses}`}>
        <span>+ {membershipTier}</span>
      </span>
    );
  };

  const renderLegacyTag = (): React.ReactElement => {
    return (
      <>
        {!forEssentials && newRelease && <div className={`${bigOtherTagClasses} bg-a-green`}>new</div>}

        {!forEssentials && free && <div className={`${bigOtherTagClasses} bg-a-blue`}>free</div>}

        {!forEssentials && renderLegacyMembershipTierMarker()}

        {!forEssentials && varietyPack && (
          <span
            className={`!bg-[rgba(255,157,0,1)] text-gray-800 flex pt-[.5px] ${containerClassCheck(props) ? '!text-[14px] w-[102px] h-[25px]' : 'w-[85px] h-[2opx]'
              } text-[12px] rounded-[3px] font-bold px-2 uppercase`}>
            <span className="flex items-center">
              <VarietyPackIcon
                data-cy="variety-pack-icon"
                className={`inline ${containerClassCheck(props) && '!w-[15px]'} w-[10px] mr-2`}
              />
              variety
            </span>
          </span>
        )}

        {!forEssentials && avfxplus == 'avfxplus' && (
          <span className={avfxplusFlat}>
            <span>avfx +</span>
          </span>
        )}

        {!forEssentials && custom && <span>{text}</span>}
      </>
    );
  };


  const renderTag = (): React.ReactElement => {

    return (
      <div className='flex gap-2'>

        {!isCollectionPage && <RenderEssentialsMarker membershipTier={membershipTier} isCollectionPage={false} />}

        {forEssentials && <RenderEssentialsMarker membershipTier={membershipTier} isCollectionPage={true} />}

        {!forEssentials && newRelease && <div className={`max-h-[20px] flex items-center ${bigOtherTagClasses} bg-a-green`}>new</div>}

        {!forEssentials && free && <div className={`${bigOtherTagClasses} bg-a-blue`}>free</div>}

        {!forEssentials && varietyPack && (
          <span
            className={`!bg-[rgba(255,157,0,1)] text-gray-800 flex pt-[.5px] ${containerClassCheck(props) ? '!text-[14px] w-[102px] h-[25px]' : 'w-[85px] h-[2opx]'
              } text-[12px] rounded-[3px] font-bold px-2 uppercase`}>
            <span className="flex items-center">
              <VarietyPackIcon
                data-cy="variety-pack-icon"
                className={`inline ${containerClassCheck(props) && '!w-[15px]'} w-[10px] mr-2`}
              />
              variety
            </span>
          </span>
        )}
        {!forEssentials && custom && <span>{text}</span>}
      </div>
    );
  };

  const renderContent = (): React.ReactElement => {
    if (isLegacyUser) {
      return renderLegacyTag();
    } else {
      return renderTag();
    }
  };

  return (
    <div className={containerClasses} data-testid="tag-container">
      {renderContent()}
    </div>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  subscription: state.auth.subscription
});

export default connect(mapStateToProps)(TagContainer);
